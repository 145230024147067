<template>
<div style=' width: 100vw; max-height: calc(100vh - 1px); height: calc(100vh - 1px);  '>
  <div style=" padding: 20px;  font-size: 12px; width: 100vw; max-height: calc(100vh - 100px);height: calc(100vh - 100px); box-sizing: border-box; overflow: auto;">
    <div v-html="agreementResult"></div>
  </div>
    
    <div  @click="sign" class="btn" v-if="fromPage!=='undefined'">同意签署协议</div>
</div>
</template>                                                                                                                                                         

<script>
import { getAgreement } from "@network/api";
import wx from "weixin-js-sdk";
import { Dialog } from 'vant';

export default {
  name: "agreement",
  data() {
    return {
      agreementUrl: "",
      agreementResult: "",
      fromPage:'',
    };
  },
  created() {
    // 注意url作为get请求的会直接作为网页内容返回的问题      先通过前端写死
    location.href.split('?')[0] ? this.agreementUrl = location.href.split('?')[0] : this.agreementUrl = location.href;
    location.href.split('?')[1] == 1 ? document.title = "共享经济服务协议" : document.title = '协议详情';
    // document.title = '协议详情';
    // this.agreementUrl = location.href;
    console.log(this.agreementUrl,'aaaaa',location.href.split('?'));
    this.fromPage = location.href.split('&&fromPage=')[1] 
    console.log(location.href);
    console.log(this.fromPage);
    console.log(typeof(this.fromPage));
     this.agreementUrl =  "enterpriseAgreement" + this.agreementUrl.split("agreementNoSign")[1].split('&&')[0];
    getAgreement(this.agreementUrl).then((res) => {
      if (res.code != "00000") {
        Dialog.alert({
          title: "错误",
          message: error,
        }).then(() => {});
        return;
      }
      this.agreementResult = res.data;
    });
  },
  methods:{
    sign(){
          setTimeout(() => {
                    Dialog.alert({
                      title: "",
                      message: '签署成功',
                    })
                    // 签约 fromPage 1  提现页面  2 红包提现页面   hasSilentSigned2 静默签成功回去勾选上
                    if(this.fromPage ==1){
                        wx.miniProgram.navigateBack({
                        url: '/pages/cashout/cashout'
                          })
                        wx.miniProgram.postMessage({ data:{hasSilentSigned:1 }  });
                    }else{
                        wx.miniProgram.navigateBack({
                        url: '/pages/redbag_cashout/redbag_cashout'
                          })
                        wx.miniProgram.postMessage({ data:{hasSilentSigned:2 }  });
                    }
                  }, 2000)
    }
  }
};
</script>

<style>
.btn{
    width: calc(100vw - 80px);
    background: #1ac9c4;
    height: 48px;
    margin: 20px 0 20px 40px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    border-radius: 10px;
}
</style>
